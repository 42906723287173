
import "@/assets/_base.scss"

import { WebinarDataService } from "@/services/WebinarDataServices"
import setThemeData from "@/services/setThemeData"
import { container } from "tsyringe"
import { computed, defineComponent, getCurrentInstance, ref } from "@vue/runtime-core"
import injectStrict from "@/utils/injectStrict"
import { GlobalDataKey, LoggerKey } from "@/symbols"
import VueSocialSharing from "vue-social-sharing"
import getCssVariables from "@/utils/getCssVariables"
import HubInfo from "@/models/hub/HubInfo"
import VideoPanel from "@/components/HubComponent/VideoPanel.vue"
import FilterComponent from "@/components/HubComponent/FilterComponent.vue"
import SeoJsonLdComponentVue from "@/components/shared/SeoJsonLdComponent.vue"
import HubWebinar from "@/models/hub/HubWebinar"
import { AnalyticsClient } from "@/services/AnalyticsService"
import { GoogleAnalyticsClient } from "@/services/GoogleAnalyticsService"
import { SetQueryParameter, GetQueryParameter, RemoveQueryParameter } from "@/services/ParameterServices"
import { AwEventType } from "@/models/AwEventType"
import { ContentType } from "@/models/ContentType"
import { AwEventStoreV2 } from "@/models/AwEventStoreV2"
import HubSkeleton from "@/components/skeletons/hub.vue"
import { preProcessHubData } from "@/utils/preProcessHubData"
const AmplilyWidget = defineComponent({
  components: { VideoPanel, SeoJsonLdComponentVue, FilterComponent, HubSkeleton },
  props: {
    fetchId: { type: String, required: true }
  },
  setup() {
    const QUERY_VARIABLE = "galleryPage"
    const globalData = injectStrict(GlobalDataKey).value
    const themeCss = computed(() => getCssVariables(globalData.theme_data))
    let hubInfo = ref<HubInfo | null>(null)
    let widget = ref<HTMLElement | null>(null)
    let currentTag = ref<string | null>(null)
    let loadingmsg = ref("Loading...")
    const dataService = container.resolve(WebinarDataService)
    const analytics = container.resolve(AnalyticsClient)
    const gaAnalytics = container.resolve(GoogleAnalyticsClient)
    let logger = injectStrict(LoggerKey)
    var app = getCurrentInstance()
    var filteredVideos = computed<Array<HubWebinar> | null>(() => {
      if (!hubInfo.value) return null
      return hubInfo.value.webinars.filter((x: HubWebinar) => {
        if (!currentTag.value) return true
        if (!x.tags) return false
        return x.tags!.indexOf(currentTag.value) > -1
      })
    })
    const PAGE_SIZE = 10
    var no_of_pages = computed(() => (!filteredVideos.value ? 0 : Math.ceil(filteredVideos.value.length / PAGE_SIZE)))
    var currentPage = ref(1)
    var prevPages = computed<Array<number>>(() => {
      var arr = []
      for (var i = currentPage.value - 3; i < currentPage.value; i++) if (i > 0) arr.push(i)
      return arr
    })
    var nextPages = computed<Array<number>>(() => {
      var arr = []
      for (var i = currentPage.value + 1; i < currentPage.value + 4; i++) if (i <= no_of_pages.value) arr.push(i)
      return arr
    })
    let currentPageVideos = computed<Array<HubWebinar> | null>(() => {
      if (filteredVideos.value == null) return null
      let indexMin = (currentPage.value - 1) * PAGE_SIZE
      let indexMax = indexMin + PAGE_SIZE
      return filteredVideos.value?.filter((x: HubWebinar, index: number) => index >= indexMin && index < indexMax)
    })

    if (!app) throw new Error("app cannot be null")
    app.appContext.app.use(VueSocialSharing)

    var getCurrentTag = () => currentTag
    return {
      QUERY_VARIABLE,
      widget,
      prevPages,
      nextPages,
      no_of_pages,
      currentPageVideos,
      currentPage,
      logger,
      dataService,
      hubInfo,
      themeCss,
      globalData,
      loadingmsg,
      analytics,
      gaAnalytics,
      currentTag,
      getCurrentTag
    }
  },
  created() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.dataService.getData(this.fetchId).then(async returnValue => {
        this.loadingmsg = "Could not find gallery."
        if (returnValue == undefined) {
          this.logger!.error(`Parmonic Widget error: Hub not found`)
          return
        }
        this.hubInfo = preProcessHubData(returnValue as HubInfo)
        if (!this.hubInfo) return
        setThemeData(this.hubInfo.theme_data, this.globalData)
        this.analytics.setUp(null, null, null, this.hubInfo.id, this.globalData.analyticsEventsCaptureEndPoint, this.logger)
        this.gaAnalytics.setUp(null, this.logger)
        this.initWidget()

        if (this.hubInfo.id) {
          var eventV2: AwEventStoreV2 = { eventType: AwEventType.WidgetViewed, type: ContentType.Hub }
          this.analytics.register(eventV2, null)
        }
      })
    },
    initWidget() {
      var pageNo = GetQueryParameter(this.QUERY_VARIABLE)
      if (pageNo) this.switchPage(parseInt(pageNo))
    },
    previousPage() {
      this.switchPage(this.currentPage - 1)
    },
    nextPage() {
      this.switchPage(this.currentPage + 1)
    },
    switchPage(pg_no: number) {
      if (!pg_no) return
      else if (pg_no < 1) this.currentPage = 1
      else if (pg_no > this.no_of_pages) this.currentPage = this.no_of_pages
      else {
        this.currentPage = pg_no
      }
      SetQueryParameter(this.QUERY_VARIABLE, this.currentPage.toString(), null)
      if (this.widget) this.widget.scrollIntoView(true)
    },
    resetPage() {
      this.currentPage = 1
    }
  }
})
export default AmplilyWidget
