<template>
  <div :style="themeCss">
    <div id="aw--c">
      <div id="aw-sl" style="font-size: 1em !important">
        <div class="wc" v-if="hubInfo != undefined" ref="widget">
          <SeoJsonLdComponentVue :HubWebinars="currentPageVideos" v-if="currentPageVideos != null" :key="currentPage" />
          <FilterComponent
            :tags="hubInfo.tags"
            :currentTag="getCurrentTag()"
            :pageQueryParam="QUERY_VARIABLE"
            :resetPage="resetPage"
          />
          <VideoPanel
            :webinar="webinar"
            :useDemoOverlay="hubInfo.use_demo_overlay"
            v-for="(webinar, i) in currentPageVideos"
            :key="i"
          />
          <div class="no-video-text" v-if="!hubInfo.webinars || hubInfo.webinars.length == 0">
            <p>There are no videos here! Please stay tuned.</p>
          </div>

          <div class="pageination" v-if="no_of_pages > 1">
            <div :class="`nav-btn ${currentPage == 1 ? 'disabled' : 'enabled'}`" @click="previousPage">
              <svg width="10" height="15" viewBox="0 0 10 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M7.85421 0.664269C8.2245 0.296628 8.82205 0.296628 9.19235 0.66427C9.56642 1.03567 9.56642 1.64066 9.19234 2.01205L3.66486 7.49998L9.19235 12.9879C9.56642 13.3593 9.56642 13.9643 9.19235 14.3357C8.82205 14.7033 8.2245 14.7033 7.85421 14.3357L0.969225 7.49998L7.85421 0.664269Z"
                  fill="#575757"
                />
              </svg>
              <p :class="`${currentPage == 1 ? 'disabled' : 'enabled'}`">Prev</p>
            </div>
            <div class="nav-btn" v-for="(pg, i) in prevPages" :key="i"><p v-html="pg" @click="switchPage(pg)"></p></div>
            <div class="nav-btn current"><p v-html="currentPage"></p></div>
            <div class="nav-btn" v-for="(pg, i) in nextPages" :key="i"><p v-html="pg" @click="switchPage(pg)"></p></div>

            <div :class="`nav-btn ${currentPage == no_of_pages ? 'disabled' : 'enabled'}`" @click="nextPage">
              <p :class="`${currentPage == no_of_pages ? 'disabled' : 'enabled'}`">Next</p>
              <svg width="10" height="15" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M1.94407 14.8684C1.57861 15.2409 0.981106 15.2487 0.606047 14.8859C0.22716 14.5194 0.219263 13.9145 0.588455 13.5382L6.04384 7.97861L0.445188 2.5633C0.0663002 2.19682 0.0584041 1.59188 0.427596 1.21563C0.793057 0.843186 1.39056 0.835387 1.76562 1.19816L8.73924 7.94343L1.94407 14.8684Z"
                  fill="#575757"
                />
              </svg>
            </div>
          </div>
        </div>
        <HubSkeleton :loadingmsg="loadingmsg" v-else />
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.wc {
  padding: 0px;
  max-width: unset;
  width: 100%;
  margin: 0px;
  .no-video-text {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .pageination {
    display: flex;
    margin: auto;
    margin-right: 0px;
    padding: 10px 0px;
    width: fit-content;
    .nav-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      p {
        padding: 0.5rem;
        margin: 0px;
        cursor: pointer;
      }
    }
    .nav-btn.current {
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.12) !important;
    }
    .nav-btn p.enabled {
      cursor: pointer;
    }
    .nav-btn p.disabled {
      cursor: no-drop;
    }
  }
}
</style>
<script lang="ts">
import "@/assets/_base.scss"

import { WebinarDataService } from "@/services/WebinarDataServices"
import setThemeData from "@/services/setThemeData"
import { container } from "tsyringe"
import { computed, defineComponent, getCurrentInstance, ref } from "@vue/runtime-core"
import injectStrict from "@/utils/injectStrict"
import { GlobalDataKey, LoggerKey } from "@/symbols"
import VueSocialSharing from "vue-social-sharing"
import getCssVariables from "@/utils/getCssVariables"
import HubInfo from "@/models/hub/HubInfo"
import VideoPanel from "@/components/HubComponent/VideoPanel.vue"
import FilterComponent from "@/components/HubComponent/FilterComponent.vue"
import SeoJsonLdComponentVue from "@/components/shared/SeoJsonLdComponent.vue"
import HubWebinar from "@/models/hub/HubWebinar"
import { AnalyticsClient } from "@/services/AnalyticsService"
import { GoogleAnalyticsClient } from "@/services/GoogleAnalyticsService"
import { SetQueryParameter, GetQueryParameter, RemoveQueryParameter } from "@/services/ParameterServices"
import { AwEventType } from "@/models/AwEventType"
import { ContentType } from "@/models/ContentType"
import { AwEventStoreV2 } from "@/models/AwEventStoreV2"
import HubSkeleton from "@/components/skeletons/hub.vue"
import { preProcessHubData } from "@/utils/preProcessHubData"
const AmplilyWidget = defineComponent({
  components: { VideoPanel, SeoJsonLdComponentVue, FilterComponent, HubSkeleton },
  props: {
    fetchId: { type: String, required: true }
  },
  setup() {
    const QUERY_VARIABLE = "galleryPage"
    const globalData = injectStrict(GlobalDataKey).value
    const themeCss = computed(() => getCssVariables(globalData.theme_data))
    let hubInfo = ref<HubInfo | null>(null)
    let widget = ref<HTMLElement | null>(null)
    let currentTag = ref<string | null>(null)
    let loadingmsg = ref("Loading...")
    const dataService = container.resolve(WebinarDataService)
    const analytics = container.resolve(AnalyticsClient)
    const gaAnalytics = container.resolve(GoogleAnalyticsClient)
    let logger = injectStrict(LoggerKey)
    var app = getCurrentInstance()
    var filteredVideos = computed<Array<HubWebinar> | null>(() => {
      if (!hubInfo.value) return null
      return hubInfo.value.webinars.filter((x: HubWebinar) => {
        if (!currentTag.value) return true
        if (!x.tags) return false
        return x.tags!.indexOf(currentTag.value) > -1
      })
    })
    const PAGE_SIZE = 10
    var no_of_pages = computed(() => (!filteredVideos.value ? 0 : Math.ceil(filteredVideos.value.length / PAGE_SIZE)))
    var currentPage = ref(1)
    var prevPages = computed<Array<number>>(() => {
      var arr = []
      for (var i = currentPage.value - 3; i < currentPage.value; i++) if (i > 0) arr.push(i)
      return arr
    })
    var nextPages = computed<Array<number>>(() => {
      var arr = []
      for (var i = currentPage.value + 1; i < currentPage.value + 4; i++) if (i <= no_of_pages.value) arr.push(i)
      return arr
    })
    let currentPageVideos = computed<Array<HubWebinar> | null>(() => {
      if (filteredVideos.value == null) return null
      let indexMin = (currentPage.value - 1) * PAGE_SIZE
      let indexMax = indexMin + PAGE_SIZE
      return filteredVideos.value?.filter((x: HubWebinar, index: number) => index >= indexMin && index < indexMax)
    })

    if (!app) throw new Error("app cannot be null")
    app.appContext.app.use(VueSocialSharing)

    var getCurrentTag = () => currentTag
    return {
      QUERY_VARIABLE,
      widget,
      prevPages,
      nextPages,
      no_of_pages,
      currentPageVideos,
      currentPage,
      logger,
      dataService,
      hubInfo,
      themeCss,
      globalData,
      loadingmsg,
      analytics,
      gaAnalytics,
      currentTag,
      getCurrentTag
    }
  },
  created() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.dataService.getData(this.fetchId).then(async returnValue => {
        this.loadingmsg = "Could not find gallery."
        if (returnValue == undefined) {
          this.logger!.error(`Parmonic Widget error: Hub not found`)
          return
        }
        this.hubInfo = preProcessHubData(returnValue as HubInfo)
        if (!this.hubInfo) return
        setThemeData(this.hubInfo.theme_data, this.globalData)
        this.analytics.setUp(null, null, null, this.hubInfo.id, this.globalData.analyticsEventsCaptureEndPoint, this.logger)
        this.gaAnalytics.setUp(null, this.logger)
        this.initWidget()

        if (this.hubInfo.id) {
          var eventV2: AwEventStoreV2 = { eventType: AwEventType.WidgetViewed, type: ContentType.Hub }
          this.analytics.register(eventV2, null)
        }
      })
    },
    initWidget() {
      var pageNo = GetQueryParameter(this.QUERY_VARIABLE)
      if (pageNo) this.switchPage(parseInt(pageNo))
    },
    previousPage() {
      this.switchPage(this.currentPage - 1)
    },
    nextPage() {
      this.switchPage(this.currentPage + 1)
    },
    switchPage(pg_no: number) {
      if (!pg_no) return
      else if (pg_no < 1) this.currentPage = 1
      else if (pg_no > this.no_of_pages) this.currentPage = this.no_of_pages
      else {
        this.currentPage = pg_no
      }
      SetQueryParameter(this.QUERY_VARIABLE, this.currentPage.toString(), null)
      if (this.widget) this.widget.scrollIntoView(true)
    },
    resetPage() {
      this.currentPage = 1
    }
  }
})
export default AmplilyWidget
</script>
